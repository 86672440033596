<template>
  <div>
    <CRow>
      <CCol class="col-12">
      </CCol>
    </CRow>
    <CRow>
      <CCol class="col-12 col-md-6 col-xl-4">
        <CCard>
          <CCardHeader>
            <strong>Şikayet Bilgisi</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol class="p-2">
                <CRow>
                  <CCol class="mb-2 ml-5">
                    <strong>Chat ID'si:</strong>
                  </CCol>
                  <CCol>
                    {{ complaintChatSessionId }}
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow>
                  <CCol class="mb-2 ml-5">
                    <strong>Randevu ID'si:</strong>
                  </CCol>
                  <CCol>
                    {{ complaintAppointmentId }}
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow class="justify-content-center align-items-center">
                  <CCol class=" mb-2 ml-5">
                    <strong> Şikayetçi: </strong>
                  </CCol>
                  <CCol class="d-flex
                 align-items-center">
                    <div class="d-flex justify-content-center">
                      <div style="width:32px;height:32px"
                           class="d-flex mr-2 justify-content-center
                 align-items-center
                 rounded-circle
                 border border-dark">
                        <img
                            @error="fallback"
                            class="c-avatar-img"
                            :src="complainantAvatar"/>
                      </div>
                    </div>
                    {{ complainantInfo.complainantName }}
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow>
                  <CCol class="mb-2 ml-5">
                    <strong> Rol: </strong>
                  </CCol>
                  <CCol>
                    {{ getComplainantRole }}
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow>
                  <CCol class="mb-2 ml-5">
                    <strong> Şikayet Tarihi: </strong>
                  </CCol>
                  <CCol>
                    {{ complaintDate | formatDateHour }}
                  </CCol>
                </CRow>
                <c-dropdown-divider/>
                <CRow>
                  <CCol class="mb-2 ml-5">
                    <strong>Şikayet Kategorisi:</strong>
                  </CCol>
                  <CCol>
                    <CBadge :color="getComplaintCategoryColor"> {{ getComplaintCategory }}</CBadge>
                  </CCol>
                </CRow>
                <!-- <CRow>
                   <CCol class="mb-2 ml-5">
                     <strong>Durum:</strong>
                   </CCol>
                   <CCol>
                     {{ getComplaintState }}
                   </CCol>
                 </CRow> -->
                <c-dropdown-divider/>
                <CRow>
                  <CCol class="mb-3 ml-5">
                    <strong>Şikayet Edilen Kişi:</strong>
                  </CCol>
                  <CCol>
                    {{ complainedInfo.complainedName }}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol class="col-12 col-md-6">
        <CCard>
          <CCardHeader>
            <strong>{{ complaintTitle }}</strong>
          </CCardHeader>
          <CCardBody>
            <p>{{ complaintContent }}</p>
          </CCardBody>
        </CCard>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <strong>İşlem Yapın</strong>
                <CBadge :color="ComplaintStateBadge(this.currentComplaintState)" class="float-right">Şikayet Durumu:
                  {{ getCurrentComplaintState }}
                </CBadge>
              </CCardHeader>
              <CCardBody>
                <CRow class="d-flex align-items-center align-self-center">
                  <CCol col="6">
                    <CSelect
                        label="İşlem Durumu"
                        vertical
                        @update:value="updateComplaintState($event)"
                        :value="this.complaintState"
                        :options="[
                    { value: 'SEEN', label: 'Görüldü'},
                    { value: 'NOT_SEEN_YET', label: 'Görülmedi'},
                    { value: 'REJECTED', label: 'Reddedildi'},
                    { value: 'COMPLETED', label: 'Tamamlandı'},
                ]"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {CustomerServiceAdministration} from "@/services/api-service";


export default {
  name: "DateComplaints",
  mounted() {
    this.getComplaintInfos()
  },
  computed: {
    getCurrentComplaintState() {
      switch (this.currentComplaintState) {
        case 'REJECTED':
          return 'Reddedildi'
        case 'COMPLETED':
          return 'Tamamlandı'
        case 'NOT_SEEN_YET':
          return 'Görülmedi'
        case 'SEEN':
          return 'Görüldü'
      }
      return ''
    },
    getComplainantRole() {
      switch (this.complainantInfo.complainantRole) {
        case 'Student':
          return 'ÖĞRENCİ'
        case 'Teacher':
          return 'ÖĞRETMEN'
      }
      return ''
    },
    getComplaintCategory() {
      switch (this.complaintCategory) {
        case "USER":
          return 'Kullanıcı';
        case "APPOINTMENT":
          return 'Randevu';
        case "CHAT":
          return 'CHAT';
      }
      return ''
    },
    getComplaintCategoryColor() {
      switch (this.complaintCategory) {
        case "CHAT":
          return 'info';
        case "APPOINTMENT":
          return 'primary';
        case "USER":
          return 'secondary'
      }
      return 'danger'
    },
  },
  data() {
    return {
      currentComplaintState: '',
      complaintContent: '',
      complainantInfo: {
        complainantId: '',
        complainantName: '',
        complainantRole: '',
      },
      complainantAvatar: '',
      complainedInfo: {
        complainedId: '',
        complainedName: '',
      },
      complaintChatSessionId: '',
      complaintAppointmentId: '',
      complaintState: '',
      complaintTitle: '',
      complaintDate: '',
      complainant: '',
      complaintId: '',
      complaintCategory: '',
      isDataLoading: '',
      warningModal: false,
      toasts: [],
    }
  },
  methods: {
    fallback(event) {
      event.target.src = "https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png"
    },
    async getComplaintInfos() {
      this.isDataLoading = true;
      const id = parseInt(this.$route.params['id'])
      try {
        const result = await CustomerServiceAdministration.getComplaint(id)
        this.complaintId = result.data.complaint.complainId
        this.complaintCategory = result.data.complaint.complaintCategory
        this.complaintDate = result.data.complaint.complaintDate
        this.complaintTitle = result.data.complaint.complaintTitle
        this.complaintState = result.data.complaint.complaintState
        this.currentComplaintState = result.data.complaint.complaintState
        this.complaintContent = result.data.complaint.complaintContent
        this.complaintAppointmentId = result.data.complaint.complaintAppointmentId
        this.complaintChatSessionId = result.data.complaint.complaintChatSessionId
        this.complainedInfo = {
          complainedId: result.data.complaint.complained.complainedId,
          complainedName: result.data.complaint.complained.complainedName
        }
        this.complainantInfo = {
          complainantId: result.data.complaint.complainant.complainantId,
          complainantName: result.data.complaint.complainant.complaininatName,
          complainantRole: result.data.complaint.complainant.complainantRole,
        }
        this.complainantAvatar = result.data.complaint.complainant.complainantAvatar1X

      } catch (e) {
        this.toasts.push("Bir Sorunla Karşılaşıldı", "Hata")
      }
      this.isDataLoading = false;
    },
    async updateComplaintState(event) {
      this.isDataLoading = true
      const id = parseInt(this.$route.params['id'])
      try {
        this.complaintState = event
        switch (event) {
          case 'COMPLETED':
            await CustomerServiceAdministration.changeComplaintStateToCompleted(id)
              this.$toast.success("Şikayet Durumu 'Tamamlandı' Olarak Güncellendi.")
            break;
          case 'REJECTED':
            await CustomerServiceAdministration.changeComplaintStateToRejected(id)
            this.$toast.success("Şikayet Durumu 'Reddedildi' Olarak Güncellendi.")
            break;
          case 'NOT_SEEN_YET':
            await CustomerServiceAdministration.changeComplaintStateToNotSeenYet(id)
            this.$toast.success("Şikayet Durumu 'Görülmedi' Olarak Güncellendi.")
            break;
          case 'SEEN':
            await CustomerServiceAdministration.changeComplaintStateToSeen(id)
            this.$toast.success("Şikayet Durumu 'Görüldü' Olarak Güncellendi.")
            break;

        }
        this.currentComplaintState = this.complaintState
      } catch (e) {
          console.log(e)
      }
      this.isDataLoading = false;
    },
    ComplaintStateBadge(status) {
      switch (status) {
        case "COMPLETED":
          return 'success';
        case "NOT_SEEN_YET":
          return 'warning';
        case "REJECTED":
          return 'danger';
        case "SEEN":
          return 'info';
        default:
          return 'danger';
      }
    },
    showToast: function (text = 'Değişiklikler Başarıyla Kaydedildi', header = 'Bilgi') {
      this.toasts.push({text, header});
    },
    getBadge(status) {
      switch (status) {
        case "Öğretmen":
          return 'success';
        case "İşlem Yapılmadı":
          return 'warning';
        case "Randevu Şikayeti":
          return 'dark';
        default:
          return 'light';
      }
    },
    showAlert() {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
              'Silindi!',
              'İşlem Başarıyla Gerçekleştirildi!',
              'success'
          )
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
